'use client'

import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'

import { useState } from 'react'

export default function Contact() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
  })

  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(null)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(null)
    setSubmitted(false)

    try {
      const response = await fetch('/api/contact-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      if (response.ok) {
        setSubmitted(true)
      } else {
        throw new Error('Something went wrong')
      }
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <div className="relative bg-amber-50">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-amber-50" />
      </div>
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div className="bg-emerald-600 px-6 py-16 lg:col-span-2 lg:px-8 lg:py-24 sm:rounded-3xl xl:pr-12">
          <div className="mx-auto max-w-lg">
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-3xl">Masz pytania? Napisz!</h2>
            <p className="mt-3 text-lg leading-6 text-gray-100">
              Pamiętaj, jesteśmy do Twojej dyspozycji 24/7. Skontaktuj się z nami, jeśli masz pytania, sugestie lub uwagi.
            </p>
            <dl className="mt-8 text-base text-gray-100">
              <div>
                <dt className="sr-only">Adres</dt>
                <dd>
                  <p className="text-xl font-bold">ozejobs.pl</p>  
                  <p>Prosta 20</p>
                  <p>00-850, Warszawa</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Telefon</dt>
                <dd className="flex">
                  <PhoneIcon aria-hidden="true" className="h-6 w-6 flex-shrink-0 text-gray-100" />
                  <span className="ml-3">+48 501 559 829</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <EnvelopeIcon aria-hidden="true" className="h-6 w-6 flex-shrink-0 text-gray-100" />
                  <span className="ml-3">kontakt@ozejobs.pl</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-amber-50 px-6 py-16 lg:col-span-3 lg:px-8 lg:py-24 xl:pl-12">
          <div className="mx-auto max-w-lg lg:max-w-none">
            {submitted ? (
              <p className="text-lg text-green-600">Dziękujemy! Wiadomość została wysłana.</p>
            ) : (
              <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-y-6">
                <div>
                  <label htmlFor="fullName" className="sr-only">
                    Imię i nazwisko
                  </label>
                  <input
                    id="fullName"
                    name="fullName"
                    type="text"
                    placeholder="Imię i nazwisko"
                    autoComplete="name"
                    className="block w-full rounded-md border-gray-300 px-4 py-3 placeholder-gray-500 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    className="block w-full rounded-md border-gray-300 px-4 py-3 placeholder-gray-500 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Telefon
                  </label>
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="Telefon"
                    autoComplete="tel"
                    className="block w-full rounded-md border-gray-300 px-4 py-3 placeholder-gray-500 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Wiadomość
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    placeholder="Wiadomość"
                    className="block w-full rounded-md border-gray-300 px-4 py-3 placeholder-gray-500 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                {error && <p className="text-red-600">{error}</p>}
                <div>
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-emerald-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                  >
                    Wyślij
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
